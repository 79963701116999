<template>
  <!-- 腾讯地图 -->
  <section>
    <div id="container"></div>
    <span class="text-warning">&nbsp;可拖动放大</span>
  </section>
</template>

<script>
export default {
  name: "Tx-Map",
  props: {},
  comments: {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    init(zoom = 8, lat = 37.434942, lng = 118.624994, storeAddress) {
      // console.log("地图初始化-纬度--lat", lat);
      // console.log("地图初始化-经度--lng", lng);
      let that = this;
      //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      //设置地图中心点23.140873000522223, 113.34551811218262
      var myLatlng = new qq.maps.LatLng(lat, lng);
      //定义工厂模式函数
      var myOptions = {
        zoom, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      };
      //获取dom元素添加地图信息
      var map = new qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );

      var marker;
      if (storeAddress) {
        if (!marker) {
          marker = new qq.maps.Marker({
            position: myLatlng,
            draggable: true,
            map: map,
          });
        }
      }
      // 添加地图点击事件
      qq.maps.event.addListener(map, "click", function (event) {
        // event.latLng.getLat() -- 纬度
        // event.latLng.getLng() -- 经度
        let map_key = "GX3BZ-2XRK4-JIOUX-XP53Z-P2AV3-VIBZU";
        that
          .$jsonp(
            `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&key=${map_key}&location=${event.latLng.getLat()},${event.latLng.getLng()}`
          )
          .then((res) => {
            that.$emit("setAddress", res.result);
            myLatlng = new qq.maps.LatLng(
              res.result.location.lat,
              res.result.location.lng
            );
            marker.setMap(null); //清除地图的所有marker标点
            marker = new qq.maps.Marker({
              position: myLatlng,
              draggable: true,
              map: map,
            });
          })
          .catch((err) => {});
      });
    },
  },
};
</script>

<style  scoped>
#container {
  width: 100%;
  min-height: 500px;
  display: inline-block;
  resize: both;
  overflow: auto;
}
</style>

