import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../components/Login.vue'
import Index from '../components/Index.vue'
import Welcome from '../components/Welcome.vue'
import StoreList from '../components/Store/Stores.vue'
import StoreAdd from '../components/Store/Add.vue'
import StoreEdit from '../components/Store/Edit.vue'
import Swiper from '../components/Store/Swiper.vue'
import UserList from '../components/User/Users.vue'
import CardList from '../components/Card/Cards.vue'
import OrderList from '../components/Order/Orders.vue'
import CoachList from '../components/Coach/Coachs.vue'
import MerchantList from '../components/Merchant/Merchants.vue'
import Setting from '../components/Setting/EditInfo.vue'

import StoreCard from '../components/Setting/StoreCard.vue'
import Fault from '../components/Setting/Fault.vue'
import OpenInfo from '../components/Info/Infos.vue'
import Map from '../components/Store/Map.vue'


Vue.use(VueRouter)

const routes = [

  { path: '/admin', component:Admin },
  { path: '/index', component: Index, redirect:'/welcome', children:[
    { path:'/welcome', component:Welcome},
    { path:'/stores', component:StoreList},//门店列表 
    { path:'/storeadd', component:StoreAdd},//添加门店
    { path:'/storeedit/:id', component:StoreEdit},//编辑门店
    { path:'/swiper', component:Swiper},//轮播图设置
    { path:'/users', component:UserList},//用户列表 
    { path:'/cards', component:CardList},//会员卡列表 
    { path:'/orders', component:OrderList},//订单列表
    { path:'/coachs', component:CoachList},//助教列表
    { path:'/merchants', component:MerchantList},//商家列表
    { path:'/map', component:Map},//地图
    { path:'/openinfo', component:OpenInfo},//开店信息

    // ------------------------商家------------------------------
    { path:'/setting', component:Setting},//门店设置
   
    { path:'/storecard', component:StoreCard},//会员卡设置
    { path:'/fault', component:Fault},//故障信息
    

    
  ]}
]

const router = new VueRouter({
  routes
})

//挂载路由导航守卫
router.beforeEach((to,from,next) => {
	if(to.path === '/admin') return next();
	const tokenStr = window.sessionStorage.getItem('admin_token')

  if(!tokenStr){
    
      return next('/')
    
  }else{
   
    // const role = window.sessionStorage.getItem('role_token')

    // if(to.path ==='/stores'){
    //   if(role === 1){
    //     return next()
    //   }
    //   else{
    //     return next('/');
    //   }
    // }
  }
	next()
})
export default router
