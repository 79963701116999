<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>助教管理</el-breadcrumb-item>
            <el-breadcrumb-item>助教列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-input placeholder="请输入助教编号" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle">
                <el-table-column label="ID" type="index"  width="50" fixed></el-table-column>
                <el-table-column label="助教编号" prop="bh" width="100"></el-table-column>
                <el-table-column label="助教昵称" prop="nickname" width="120"></el-table-column>
                <el-table-column label="用户名" prop="username" width="120"></el-table-column>
                <el-table-column label="头像" prop="avatar" width="60">
                    <template slot-scope="scope">
                        <div v-if="scope.row.avatar">
                            <el-popover placement="bottom"  width="180" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/'+scope.row.avatar"  style="width:170px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/'+scope.row.avatar" style="height: 30px;">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="展示照片" prop="photos" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.photos.length > 0">
                            <el-popover placement="bottom"  width="180" trigger="click" v-for="(item,index) in scope.row.photos" :key="index">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/'+item"  style="width:170px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/'+item" style="height: 30px;">
                            </el-popover>
                            <!-- <el-popover placement="bottom"  width="180" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/'+scope.row.photos[1]"  style="width:170px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/'+scope.row.photos[1]" style="height: 30px;">
                            </el-popover>
                            <el-popover placement="bottom"  width="180" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/'+scope.row.photos[2]"  style="width:170px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/'+scope.row.photos[2]" style="height: 30px;">
                            </el-popover> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="健身地点" prop="storename" width="300"></el-table-column>
                <el-table-column label="价格" prop="price" width="150">
                    <template slot-scope="scope" v-if="scope.row.price">
                        ￥{{scope.row.price}}/小时
                    </template>
                </el-table-column>
                <el-table-column label="审核" prop="ischeck" width="80">
                    <template  slot-scope="scope">
                        <el-switch active-value="1" inactive-value="0" v-model="scope.row.ischeck" @change="Checked(scope.row.id,scope.row.ischeck,scope.row.username)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="申请日期" prop="adddate"></el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            imgURL:'http://47.104.183.145:8808/',
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            list: [],
            total: 0,
        }
    },
    created() {
         this.getList()
    },
    methods:{
        async getList() {
           const { data: res} = await this.$http.get('coachlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                    this.list[index].id= res.data.list[index].pk;
                }
       },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async DelList(id){
                const confirmResult = await this.$confirm('是否要删除此订单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('orderdel/'+ id)
                    // console.log(res)
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getOrderList()
       },
       async Checked(id,sign,username){
          const { data: res} = await this.$http.get('coachcheck/',{ params:{ id:id,sign:sign,username:username }})
          if(res.status === 0){
             this.$message({message: '审核通过！',type: 'success'}) 
             this.getList()
          }
          if(res.status === 1){
             this.$message({message: '审核取消！',type: 'warning'}) 
             this.getList()
          }
      }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
</style>