<script>
    // const imgURL='http://47.104.183.145:6606/';
    // const imgURL='http://192.168.0.101:8000/';
    // const imgURL='http://127.0.0.1:8008/';
    // const imgURL='https://jianshenhtapi.girlfee.com/';
    const imgURL='https://jianshenhtapi.tieshouyundong.com/';
    
    
    export default
    {
        imgURL
    }
</script>