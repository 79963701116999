<template>
    <div class="login_container">
        <div class="title">共享健身后台管理系统</div>
       <div class="login_box">
			<div class="avater_box">
				<img src="../assets/image/logo.jpg" alt="">
			</div>
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username"  prefix-icon="el-icon-user-solid" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" placeholder="请输入密码" type="password" @keypress.native.enter="login"></el-input>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button type="primary" @click="login" >登录</el-button>
                    <el-button type="waring" @click="resetLoginForm">重置</el-button>
                </el-form-item>
            </el-form>
		</div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            loginForm: {
                username: '',
                password: ''
            },
            loginFormRules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 8, message: '用户名长度在 3 到 8 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 8, message: '密码长度在 6 到 8 个字符', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        resetLoginForm() {
            this.$refs.loginFormRef.resetFields();
        },
        login() {
            this.$refs.loginFormRef.validate( async valid => {
                if(!valid) return;
                const { data:res } = await this.$http.post('adminlogin/', this.loginForm);
                if (res.status !== 0) return this.$message.error('登录失败！');
                this.$message.success('登录成功！');
                window.sessionStorage.setItem('admin_token',res.username);
                window.sessionStorage.setItem('name_token',res.name);
                window.sessionStorage.setItem('role_token',res.role);
                window.sessionStorage.setItem('storeid_token',res.storeid);
                
                
                window.sessionStorage.setItem('pass_token',res.password);
                this.$router.push('/index');
            })
        }
    }
}
</script>
<style scoped>
	.login_container{
      /* background-color:#2b4b6b; */
       background: url(../assets/image/bg.png);
       background-position:50% 50%;
       background-size:cover;
       background-repeat:no-repeat;
       height: 100%;
       min-width: 1024px
    }
    .login_box {
	    	width: 450px;
	    	height: 300px;
	    	background-color:#fff;
	    	border-radius: 8px;
	    	position:absolute;
	    	left:50%;
	    	top:50%;
	    	transform:translate(-50%, -50%);
	}
    .avater_box {
        height: 100px;
        width: 100px;
        border: 1px solid #eee;
        border-radius:50%;
        padding: 10px;
        box-shadow:0 0 10px #ddd;
        position:absolute;
        left:50%;
        transform:translate(-50%,-50%);
        background-color:#fff;
    }
    .avater_box img{
        height: 100%;
        width: 100%;
        border-radius:50%;
        background-color: #eee
    }
    .login_form {
    	position:absolute;
    	bottom:0;
    	width: 100%;
    	padding:0 50px;
    	box-sizing:border-box;
    }
    .btns {
    	display: flex;
    	justify-content: flex-end;
    }
    .title{
        font-size:35px;font-family:微软雅黑;color:#ffffff;position:absolute;margin-top:50px;left:50%;transform:translate(-50%,-50%);
    }
</style>