<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>门店列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入门店名称" clearable v-model="queryInfo.query" @clear="getList()">
                        <el-button slot="append" icon="el-icon-search" @click="getList()"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="14"><el-button type="primary" @click="AddStore">添加门店</el-button></el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--商品列表区域-->
            <el-table :data="list" style="width: 100%" stripe size="middle" border>
                <el-table-column label="ID" type="index"  fixed></el-table-column>
                <el-table-column label="门店名称" prop="storename" width="200" show-overflow-tooltip fixed></el-table-column>
                <el-table-column label="所属城市" prop="cityid" width="150">
                    <template slot-scope="scope">
                        {{scope.row.city}} {{scope.row.county}}
                    </template>
                </el-table-column>
                <el-table-column label="门店图片" prop="storepic" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.storepic">
                            <el-popover placement="bottom"  width="600" trigger="click">
                                <div style="text-align: center;">
                                <img :src="imgURL+'static/pics/'+scope.row.storepic"  style="width:600px"/>
                                </div>
                                <img  slot="reference" :src="imgURL+'static/pics/'+scope.row.storepic" style="width:30px">
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="进店1Sn" prop="sn" width="100"/>
                <el-table-column label="出店1Sn" prop="outsn" width="100"/>
                <el-table-column label="进店2Sn" prop="sn1" width="100"/>
                <el-table-column label="出店2Sn" prop="outsn1" width="100"/>
                <el-table-column label="鱼眼摄像头1Sn" prop="fishsn" width="220"/>
                <el-table-column label="鱼眼摄像头2Sn" prop="fishsn1" width="220"/>
                <el-table-column label="摄像头1Sn" prop="camera1" width="190"/>
                <el-table-column label="摄像头2Sn" prop="camera2" width="190"/>
                <el-table-column label="摄像头3Sn" prop="camera3" width="190"/>
                <el-table-column label="摄像头4Sn" prop="camera3" width="190"/>
                <el-table-column label="边缘盒子Sn" prop="boxsn" width="190"/>
                <el-table-column label="温湿度Sn" prop="wsdsn" width="190"/>
                <el-table-column label="操房数量" prop="roomnum" width="100">
                    <template slot-scope="scope">
                        {{scope.row.roomnum}} 间
                    </template>
                </el-table-column>
                <el-table-column label="门店面积" prop="storearea" width="100">
                    <template slot-scope="scope">
                        {{scope.row.storearea}}  ㎡
                    </template>
                </el-table-column>
                <el-table-column label="器械种类" prop="devicetype" width="100">
                    <template slot-scope="scope">
                        {{scope.row.devicetype}}  种
                    </template>
                </el-table-column>
                <el-table-column label="微信" prop="wechat" width="100"></el-table-column>
                <el-table-column label="电话" prop="telephone" width="150"></el-table-column>
                <el-table-column label="Wifi" prop="wifi" width="150"></el-table-column>
                <el-table-column label="状态" prop="isopen" width="100" fixed="right">
                    <template slot-scope="scope">
                       <el-tag v-if="scope.row.isopen=='1'"  type="success">营业</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='2'" type="info">关闭</el-tag>
                       <el-tag v-else-if="scope.row.isopen=='3'" type="warning">装修中</el-tag>
                    </template>
                </el-table-column>
                
                <el-table-column label="操作" width="150" fixed="right">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                        <!-- {{scope.row}} -->
                        <el-button type="success" icon="el-icon-edit" size="mini" @click="EditStore(scope.row.id)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelStore(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--商品列表区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            imgURL:this.GLOBAL.imgURL,
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: [],
            addDialogVisible:false,
            editDialogVisible:false,
            addForm:{
                 username:''
            },
             //编辑表单数据
             editForm:{
                username:'',
                iscard:0,
                isdoor:0,
                isface:0
            },
            //添加表单验证规则
            addFormRules:{
                username:[
                    { required: true, message: '请输入门店名称', trigger: 'blur' }
                ]
            },
            editFormRules:{
                username:[
                    { required: true, message: '请输入门店名称', trigger: 'blur' }
                ]
            },
        }
        
    },
    created() {
         this.getList()
    },
    mounted(){
    },
    
    methods:{
        async getList() {
            const { data:res } = await this.$http.get('storelist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       
       AddStore(){
             this.$router.push({path: '/storeadd'})
       },
       async EditStore(id){
        this.$router.push({ path: `/storeedit/${id}` })
       },
       async DelStore(id){
                const confirmResult = await this.$confirm('是否要删除此门店吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('/storedel/'+ id)
                
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getList()
       },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>