<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入会员手机号" clearable v-model="queryInfo.query" @clear="getUserList()">
                        <el-button slot="append" icon="el-icon-search" @click="getUserList()"></el-button>
                    </el-input>
                </el-col>
                <!-- <el-col :span="2" style="line-height: 40px;">
                    <el-checkbox v-model="queryInfo.tx_user" @change="getUserList">提现用户</el-checkbox>
                </el-col>
                <el-col :span="2" style="line-height: 40px;">
                    <el-checkbox v-model="queryInfo.lock_user" @change="getUserList">锁定用户</el-checkbox>
                </el-col> -->
                <!-- <el-col :span="14"><el-button type="primary" @click=" addDialogVisible = true">添加用户</el-button></el-col> -->
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="userlist"  :key="userlist.index" style="width: 100%" stripe size="middle" height="430">
                <el-table-column label="ID" type="index"  width="40"></el-table-column>
                <el-table-column label="用户名" prop="username" width="150"></el-table-column>
                <el-table-column label="昵称" prop="nickname" width="150"></el-table-column>
                <el-table-column label="性别" prop="sex" width="100"></el-table-column>
                <el-table-column label="年龄" prop="age" width="100"></el-table-column>
                <!-- <el-table-column label="身高" prop="height" width="100"></el-table-column> -->
                <!-- <el-table-column label="禁止购卡" prop="iscard" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.iscard == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="禁止门禁" prop="isdoor" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.isdoor == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="禁止录脸" prop="isface" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.isface == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column label="是否店主" prop="ismanager" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.ismanager == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column> -->
                
                <el-table-column label="注册门店" prop="storename" width="200"></el-table-column>

                <el-table-column label="注册时间" prop="adddate" width="200"></el-table-column>
                <el-table-column label="角色" prop="role" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.role == '0'">用户</el-tag>
                        <el-tag type="danger" v-if="scope.row.role == '1'">助教</el-tag>
                        <el-tag type="warning" v-if="scope.row.role == '2'">店主</el-tag>
                        <el-tag  v-if="scope.row.role == '3'">店长</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否锁定" prop="islock" width="100">
                    <template  slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.islock == '0'">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope"> 
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="EditUser(scope.row.username)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelUser(scope.row.username)"></el-button>
                        <el-tooltip class="item" effect="dark" content="提现记录" placement="top">
                            <el-button type="warning" icon="el-icon-sort" size="mini" @click="TiXian(scope.row.username)"></el-button>
                        </el-tooltip>
                </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加用户对话框-->
            <el-dialog title="添加会员" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="addForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="是否是店主" prop="ismanager">
                        <el-switch active-value="1" inactive-value="0" v-model="addForm.ismanager"></el-switch>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addUser">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加用户对话框-->
            <!--编辑用户对话框-->
            <el-dialog title="编辑会员" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="editForm.username" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="是否是店主" prop="ismanager">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.ismanager"></el-switch>
                    </el-form-item>
                    <el-form-item label="关联门店" prop="storeid" v-if="editForm.role == '2'">
                        <el-select  v-model="editForm.storeid"  placeholder="请选择类型" style="width:300px">
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否锁定" prop="islock">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.islock"></el-switch>
                    </el-form-item>
                    <!-- <el-form-item label="禁止在线购卡" prop="iscard">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.iscard"></el-switch>
                    </el-form-item>
                    <el-form-item label="禁止开启门禁" prop="isdoor">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.isdoor"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否录入人脸" prop="isface">
                        <el-switch active-value="1" inactive-value="0" v-model="editForm.isface"></el-switch>
                    </el-form-item> -->
                    
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="EditUserSave(editForm.username,editForm.id)">确 定</el-button>
                </span>
            </el-dialog>
            <!--编辑用户对话框-->
            <!--提现记录对话框-->
            <el-dialog title="提现记录" :visible.sync="txDialogVisible" width="50%" >
                <el-card>
                    <!-- {{this.txlist0}} -->
                    <el-table :data="txlist0"  :key="txlist0.id" style="width: 100%"  size="small" height="50" :show-header="false" >
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="reg_time"></el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="warning">待审</el-tag>
                             </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <!--作用域插槽-->
                            <template slot-scope="scope">
                                <!-- {{scope.row}} -->
                                <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.sign" @change="Checked(scope.row.id)"></el-switch>  <span style="color:#ff0000">审核</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <br>
                <el-card>
                    <el-table :data="txlist1"  :key="txlist1.id" style="width: 100%" stripe size="small" height="300">
                        <el-table-column label="ID" type="index"></el-table-column>
                        <el-table-column label="用户" prop="username"></el-table-column>
                        <el-table-column label="提现金额" prop="amount">
                            <template slot-scope="scope">
                            ￥{{scope.row.amount}}
                        </template>
                        </el-table-column>
                        <el-table-column label="审核状态">
                             <template>
                                 <el-tag type="success">已审</el-tag>
                             </template>
                        </el-table-column>
                        <el-table-column label="提现时间" prop="add_time"></el-table-column>
                    </el-table>
                </el-card>
            </el-dialog>
            <!--提现记录对话框-->
        </el-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
       //验证手机的规则
		var checkMobile = (rule,value,cb) => {
			const regMobile = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/
			if(regMobile.test(value)){
				return cb()
			}
			cb(new Error('请输入合法的手机号！'))
		}
        return{
            
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            userlist: [],
            storelist:[],
            txlist0:[],
            txlist1:[],
            addDialogVisible:false,
            editDialogVisible:false,
            txDialogVisible:false,
            addForm:{
                 username:'',
                 ismanager:0
            },
             //编辑表单数据
             editForm:{
                username:'',
                iscard:0,
                isdoor:0,
                isface:0,
                ismanager:0,
                islock:0
            },
            //添加表单验证规则
            addFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur'}
                ]
            },
            editFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur'}
                ]
            },
        }
        
    },
    created() {
         this.getUserList()
    },
    mounted(){
    },
    methods:{
        async getUserList() {
           const { data: res} = await this.$http.get('userlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.userlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.userlist[index].id= res.data.list[index].pk;
            }
            console.log(this.userlist)
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getUserList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getUserList()
       },
       addDialogClosed(){
            this.$refs.addFormRef.resetFields()
       },
       
       async addUser(){
              this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                const { data:res } = await this.$http.post('useradd/',this.addForm)
                if(res.status===0)
                {
                    this.$message.success('添加用户成功！')
                    this.addDialogVisible = false
                    this.getUserList()
                }
                if(res.status == 1){
                    this.$message.error('此用户已存在！')
                }
              })
       },
       async EditUser(username){
            const { data: res } = await this.$http.get('userinfo/'+username)
            this.editForm = res
            if(res.role == '2'){
                this.editForm.ismanager = '1'
            }
            
            this.editDialogVisible = true
            this.GetStore()
       },
       async GetStore(){
            const { data: res } = await this.$http.get('store/')
            if(res.status == 0){
                this.storelist = res.data
            }
       },
       EditUserSave(username,userid){
           this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('useredit/' + username,this.editForm)
                if (res.status === 0) {
                    this.$message.success('用户修改成功')
                    this.editDialogVisible = false
                    this.getUserList()
                    console.log(this.editForm.islock);
                    var usertype = 0
                    if(this.editForm.islock == 1){
                        usertype = 10
                    }
                }
            })
       },
       editDialogClosed(){
            this.$refs.editFormRef.resetFields()
       },
       async DelUser(username){
                const confirmResult = await this.$confirm('是否要删除此用户吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('userdel/'+ username)
                
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getUserList()
       },
       async TiXian(username){
          this.txDialogVisible = true
          const { data: res} = await this.$http.post('tixianlist/',{ username:username })
          this.txlist0 = res.arr0
          this.txlist1 = res.arr1
       },
       async Checked(id){
        try {
            // const payload = { name: 'John', age: 30 };
            const { data: res} = await axios.post('https://jianshenapi.tieshouyundong.com/tixian');
            console.log("响应数据：", res);
        } catch (error) {
            console.error("请求出错：", error);
        }
        // try {
        //   const { data: res} = await this.$http.post('wxtixian/',{ params:{ id:id }})
        //   if(res.status === 0){
        //      this.$message({message: '提现审核成功！'+res.orderno,type: 'success'}) 
        //     //  this.TiXian(res.username)
        //     //  this.getUserList()
        //   }
        // } catch (error) {
        //     console.error("请求出错：", error);
        // }
        
      },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
    height: 0px;
    
    }
</style>