<template>
    <!-- 在vue项目中使用腾讯地图 -->
    <div>
      <el-form
        :model="form"
        ref="ruleForm"
        label-width="180px"
        class="form"
        size="small"
      >
        <el-form-item label="门店地址：">
          <el-input
            style="width: 400px"
            v-model="form.store_address"
            placeholder="请输入门店详细地址"
            clearable
            @input="addressChange(15)"
          ></el-input>
          <el-button
            type="primary"
            plain
            clearable
            @click="addressChange(15)"
            >搜索地址</el-button
          ></el-form-item
        >
        <el-form-item label="门店定位">
          <tx-map ref="map" @setAddress="setAddress" />
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import TxMap from "../../components/common/TXMap"
  export default {
    components: { TxMap },
    data() {
      return {
        form: {
          store_address: "东营万方购物广场",
          prov_name: "",
          city_name: "",
          district_name: "",
          latitude: "",
          longitude: "",
        },
      };
    },
    methods: {
      //更新地点
      setAddress(newAddress) {
        this.form.store_address =
          newAddress.formatted_addresses.recommend; //地址
        this.form.latitude = newAddress.location.lat; //纬度
        this.form.longitude = newAddress.location.lng; //经度
        this.form.prov_name = newAddress.address_component.province; //省
        this.form.city_name = newAddress.address_component.city; //市
        this.form.district_name = newAddress.address_component.district; //区
        this.form = Object.assign({}, this.form, {
          store_address: newAddress.formatted_addresses.recommend,
        });
        console.log("this.form.store_address", this.form.store_address);
      },
  
      // 地址搜索事件
      addressChange(zoom = 15) {
        let map_key = "GX3BZ-2XRK4-JIOUX-XP53Z-P2AV3-VIBZU"; //腾讯地图key
        //省市区
        let prov_name = this.form.prov_name || "",
          city_name = this.form.city_name || "",
          district_name = this.form.district_name || "";
        //如果有省市区的选择，地图搜索就要把省市区组装起来，传入到address参数里
        let store_city = prov_name + city_name + district_name;
  
        // 地址转坐标
        this.$jsonp(
          `https://apis.map.qq.com/ws/geocoder/v1/?output=jsonp&key=${map_key}&address=${store_city}${this.form.store_address}`
        ).then((res) => {
          // 根据点击地点获取经纬度
          let lat = res.result.location.lat; //纬度
          let lng = res.result.location.lng; //经度
          //在地图上定位地点
          this.$refs["map"].init(
            zoom,
            lat,
            lng,
            this.form.store_address
          );
        });
      },
    },
    created() {
      this.addressChange(15);
    },
  };
  </script>
  
  
  
  
  