<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>故障信息管理</el-breadcrumb-item>
            <el-breadcrumb-item>故障信息列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--商品列表区域-->
            <el-table :data="list" :key="list.index" style="width: 100%" stripe size="middle" height="420">

                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="用户" prop="username"  width="200"></el-table-column>
                <el-table-column label="描述" prop="content"  width="400"></el-table-column>
                <el-table-column label="图片" prop="pic"  width="300">
                    <template slot-scope="scope" v-if="scope.row.pic">
                        <el-popover placement="bottom"  width="340" trigger="click">
                            <div style="text-align: center;"><img :src="imgURL+'static/'+scope.row.pic" style="width:300px"></div>
                            <img :src="imgURL+'static/'+scope.row.pic" style="width:50px" slot="reference">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="发布日期" prop="adddate"  width="200"></el-table-column>
                <el-table-column label="操作" width="250">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
                </el-table>
            <!--商品列表区域-->
             <!--分页区域-->
             <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
        </el-card>
    </div>
</template>
<script>
export default {
    data() {

        return{
            imgURL:'https://jianshenapi.girlfee.com/',
            queryInfo: {
                query: '',
                storeid:'',
                pagenum: 1,
                pagesize: 10
            },
            total:0,
            list: []
        }
        
    },
    created() {
         this.queryInfo.storeid = window.sessionStorage.getItem('storeid_token')
         this.getList()
    },
    
    methods:{
        async getList() {
            
            const { data:res } = await this.$http.get('faultlist/',{ params: this.queryInfo })
            if(res.status == 1){
                this.total = res.data.totalCount;
                this.list = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                this.list[index].id= res.data.list[index].pk;
            }
        },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getList()
       },
       async DelInfo(id){
            const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('faultdel/'+ id)
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getList()
        },
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    el-table__row>td {
    border: none;
    }

    .el-table::before {
        height: 0px;
    }

</style>