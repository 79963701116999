<template>
    <div id="lpc-quill" class="quill">
        <!-- {{str}} -->
      <el-upload
        v-show="false"
        ref="upload"
        class="upload-demo"
        :action="loadUrl"
        :before-upload="beforeUpload"
        :on-success="upScuccess"
        :on-error="uploadError"
      />
      <quill-editor
        ref="myQuillEditor"
        v-model="editContent"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @change="onEditorChange($event)"
      />
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      str: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        // loadUrl:'http://127.0.0.1:8008/upload/',
        imgURL:this.GLOBAL.imgURL,
        loadUrl: this.GLOBAL.imgURL+'upload/',
        editContent:'',
        editorOption: { // 富文本编辑器配置
          placeholder: '请在这里输入',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
                // ['blockquote', 'code-block'], // 引用，代码块
                [{ 'header': 1 }, { 'header': 2 }], // 标题，键值对的形式；1、2表示字体大小
                [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 列表
                [{ 'script': 'sub' }, { 'script': 'super' }], // 上下标
                [{ 'indent': '-1' }, { 'indent': '+1' }], // 缩进
                [{ 'direction': 'rtl' }], // 文本方向
                // [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                // [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // 几级标题
                [{ 'color': [] }, { 'background': [] }], // 字体颜色，字体背景颜色
                // [{ 'font': [] }], // 字体
                [{ 'align': [] }], // 对齐方式
                ['clean'], // 清除字体样式
                ['image'] // 上传图片、上传视频
              ],
              handlers: {
                'image': function(value) {
                  if (value) {
                    document.querySelector('#lpc-quill .upload-demo input').click()
                  } else {
                    this.quill.format('image', false)
                  }
                }
              }
            }
          },
          theme: 'snow'
        }
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    watch:{
        str(val){
            this.editContent = val
        }
    },
    // created() {
    //   console.log(this.str)
    //   this.editContent = this.str // JSON.parse(JSON.stringify(this.str))
    // },
    methods: {
      beforeUpload(file) {
        console.log('上传前，可在此插入loading动画')
      },
      upScuccess(res, file) {
        console.log(res)
        const quill = this.$refs.myQuillEditor.quill
        // if (res.code === 200 && res.data !== null) {
        if(res.msg === 'success'){
          const length = quill.getSelection().index // 获取光标所在位置
          quill.insertEmbed(length, 'image', this.imgURL+'static/pics/'+res.picname) // 插入图片
          quill.setSelection(length + 1) // 调整光标位置到最后
        } else {
          this.$message.error('图片上传失败.......')
        }
        console.log(quill)
      },
      uploadError() {
        this.$message.error('图片上传失败')
      },
      onEditorReady(editor) { // 准备编辑器
        console.log(editor)

        console.log('11111111111111111111111')
      },
      onEditorBlur() {}, // 失去焦点事件
      onEditorFocus(val, editor) {}, // 获得焦点事件
      onEditorChange() {
        this.$emit('editorContent', this.editContent)
      } // 内容改变事件
    }
  }
  </script>
  
  <style scoped>
         .ql-container {
    height: 200px;
  }
  </style>