<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-input placeholder="请输入订单编号" clearable v-model="queryInfo.query" @clear="getOrderList()">
                        <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="orderlist" style="width: 100%" stripe size="small">
                <el-table-column label="ID" type="index"  width="50" fixed></el-table-column>
                <el-table-column label="订单编号" prop="orderno" width="140"></el-table-column>
                <el-table-column label="用户名" prop="username" width="100"></el-table-column>
                <el-table-column label="店铺名" prop="storename"></el-table-column>
                <el-table-column label="卡名" prop="cardname" width="180"></el-table-column>
                <el-table-column label="金额" prop="amount" width="100">
                    <template slot-scope="scope" v-if="scope.row.amount">
                        <span style="color: #ff0000;">￥{{scope.row.amount}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="订单状态" prop="status" width="90">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.order_stats==1" type="success">已支付</el-tag>
                        <el-tag v-else type="warning">未支付</el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column label="开始时间" prop="starttime" width="150"></el-table-column>
                <el-table-column label="截止时间" prop="endtime" width="150"></el-table-column>
                <el-table-column label="订单日期" prop="adddate" width="150"></el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            //获取订单列表的参数对象
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            orderlist: [],
            total: 0,
        }
    },
    created() {
         this.getOrderList()
    },
    methods:{
        async getOrderList() {
           const { data: res} = await this.$http.get('orderlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.total = res.data.totalCount;
                this.orderlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                    this.orderlist[index].id= res.data.list[index].pk;
                }
            console.log(this.orderlist)
       },
        handleSizeChange(newSize){
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getOrderList()
       },
       handleCurrentChange(newPage){
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getOrderList()
       },
       CopyText(row){
            var oInput = document.createElement('input')
            oInput.value = row.cons_address +' '+ row.cons_name +' '+ row.cons_telephone
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = 'oInput'
            oInput.style.display = 'none'
            this.$message.success('复制成功！')
        },
        async RevokeOrder(id){
            const confirmResult = await this.$confirm('是否要撤销此订单吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).catch(err => err)

                        if (confirmResult !== 'confirm'){
                            return this.$message.info('已取消删除！')
                        }
                        const { data: res } = await this.$http.get('orderrevoke/'+ id)
                        // console.log(res)
                        if (res.status === 0) { 
                            this.$message({message: '撤销成功！',type: 'success'})
                        }
                        this.getOrderList()
        },
       async DelOrder(id){
                const confirmResult = await this.$confirm('是否要删除此订单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('orderdel/'+ id)
                    // console.log(res)
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getOrderList()
       },
       async Checked(id,send_sign){
          const { data: res} = await this.$http.get('checksend/',{ params:{ id:id,sign:send_sign }})
          if(res.status === 0){
             this.$message({message: '发货审核通过！',type: 'success'}) 
             this.getOrderList()
          }
          if(res.status === 1){
             this.$message({message: '发货审核取消！',type: 'warning'}) 
             this.getOrderList()
          }
      }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
</style>