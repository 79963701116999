<template>
    <el-container class="home-container">
        <el-header>
            <div>
                <span style="font-size:25px">共享健身后台管理系统--{{name_token}}</span>
            </div>
            <div>
                <el-button @click="editDialogVisible = true">修改密码</el-button>
                <el-button type="" @click="LogOut">退出</el-button>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px">
                <el-menu default-active="2" background-color="#fff" text-color="#6e6e6e" active-text-color="#409EFF" unique-opened router  style="height: 100%">
                    <div v-if="role_token == 0">
                    <el-submenu index="1">
                        <template slot="title">
                        <i class="el-icon-s-home"></i>
                        <span>门店管理</span>
                        </template>
                        <el-menu-item index="/stores" @click="saveNavState('/stores')"><i class="el-icon-menu"></i>门店列表</el-menu-item>
                        <el-menu-item index="/swiper"  @click="saveNavState('/swiper')"><i class="el-icon-menu"></i>轮播图设置</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                        <i class="el-icon-user-solid"></i>
                        <span>商家管理</span>
                        </template>
                        <el-menu-item index="/merchants" @click="saveNavState('/merchants')"><i class="el-icon-menu"></i>商家列表</el-menu-item>
                    </el-submenu>
                    <el-submenu index="3">
                        <template slot="title">
                        <i class="el-icon-user"></i>
                        <span>会员管理</span>
                        </template>
                        <el-menu-item index="/users" @click="saveNavState('/users')"><i class="el-icon-menu"></i>会员列表</el-menu-item>
                    </el-submenu>
                    <el-submenu index="4">
                        <template slot="title">
                        <i class="el-icon-postcard"></i>
                        <span>会员卡管理</span>
                        </template>
                        <el-menu-item index="/cards"  @click="saveNavState('/cards')"><i class="el-icon-menu"></i>会员卡列表</el-menu-item>
                    </el-submenu>
                    <el-submenu index="5">
                        <template slot="title">
                        <i class="el-icon-s-order"></i>
                        <span>订单管理</span>
                        </template>
                        <el-menu-item index="/orders"  @click="saveNavState('/orders')"><i class="el-icon-menu"></i>订单列表</el-menu-item>
                    </el-submenu>
                    <el-submenu index="6">
                        <template slot="title">
                        <i class="el-icon-phone"></i>
                        <span>开店信息</span>
                        </template>
                        <el-menu-item index="/openinfo"  @click="saveNavState('/openinfo')"><i class="el-icon-menu"></i>信息列表</el-menu-item>
                    </el-submenu>
                    <el-submenu index="7">
                        <template slot="title">
                        <i class="el-icon-s-custom"></i>
                        <span>助教管理</span>
                        </template>
                        <el-menu-item index="/coachs"  @click="saveNavState('/coachs')"><i class="el-icon-menu"></i>助教列表</el-menu-item>
                    </el-submenu>
                    </div>
                    <div v-if="role_token == 1">
                        <el-submenu index="7">
                            <template slot="title">
                            <i class="el-icon-setting"></i>
                            <span>门店信息管理</span>
                            </template>
                            <el-menu-item index="/setting"  @click="saveNavState('/setting')"><i class="el-icon-menu"></i>门店设置</el-menu-item>
                            
                            <el-menu-item index="/storecard"  @click="saveNavState('/storecard')"><i class="el-icon-menu"></i>会员卡设置</el-menu-item>
                            <el-menu-item index="/fault"  @click="saveNavState('/fault')"><i class="el-icon-menu"></i>故障信息</el-menu-item>
                        </el-submenu>
                    </div>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <!--修改密码对话框-->
        <el-dialog title="修改密码" :visible.sync="editDialogVisible" width="40%" @close="editDialogClosed">
            <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                <el-form-item label="原密码" prop="password">
                    <el-input v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="new_password">
                    <el-input v-model="editForm.new_password" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="con_password">
                    <el-input v-model="editForm.con_password" show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editPass">确 定</el-button>
            </span>
        </el-dialog>
        <!--修改密码对话框-->
    </el-container>
</template>
<script>
export default {
    data(){
        //验证原密码规则
        var oldPass = (rule, value, callback) => {
            if (value !== this.old_password) {
            callback(new Error('原密码不正确!'));
            } else {
            callback();
            }
        }
        //验证确认密码规则
        var validatePass = (rule, value, callback) => {
            if (value !== this.editForm.new_password) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        }
        return {
            isCollapse: false,
            activePath: '',
            editDialogVisible:false,
            old_password:'',
            editForm:{
                 password:'',
                 new_password:'',
                 con_password:''
            },
            editFormRules: {
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { validator: oldPass, trigger: 'blur'}
                ],
                new_password: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 8, message: '长度在 6 到 8 个字符', trigger: 'blur' }
                ],
                con_password:[
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur'}
                ],
            }
        }
    },
    created() {
        this.activePath = window.sessionStorage.getItem('activePath')
        this.admin_token = window.sessionStorage.getItem('admin_token')
        this.name_token = window.sessionStorage.getItem('name_token')
        this.role_token = window.sessionStorage.getItem('role_token')
        this.storeid_token = window.sessionStorage.getItem('storeid_token')
        
        this.old_password = window.sessionStorage.getItem('pass_token')
	},
    methods:{
        saveNavState(activePath) {
			window.sessionStorage.setItem('activePath', activePath)
			this.activePath = activePath
			
        },
        LogOut(){
            window.sessionStorage.clear();
            this.$router.push('/admin');
        },
        editDialogClosed(){
           this.$refs.editFormRef.resetFields()
       },
       editPass(){
           this.$refs.editFormRef.validate( async valid => {
                if(!valid) return;
                const { data: res } = await this.$http.post('editpass/',{username:this.admin_token,password:this.editForm.new_password})
                if (res.status === 0) {
                    this.$message.success('密码修改成功！')
                    this.editDialogVisible = false
                }
            })
       },
       Print(){
           console.log('3333333333333')
       }
    }
}
</script>
<style>
    .home-container{
        padding-top:0px;
        height: 100%;
    }
    .el-header{
        /* background-color: #153d77; */
        background-image: linear-gradient(to right,#6f82e1,#5165f8);
        display: flex;
        justify-content: space-between;
        padding-left: 100px;
        align-items: center;
        font-size: 20px;
        color: #fff;
        height: 100px !important;
    }
    .el-aside{
	    background-color: #fff;
    }
    .el-main {
        background-color:#FAFAFA;
    }
    .el-menu {
		border-right: none;
    }
    .el-submenu :hover{
        background-color: lightblue !important;
    }
</style>